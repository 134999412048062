import React,{useState,useEffect} from "react";

import {
  useMutation,

} from "@apollo/client";

import { UserMutation } from "../graphql/queries";

import {Row, Col, Button, Drawer, Form, Input, Select, Space,message } from 'antd';

const { Option } = Select;

const AddUserDrawer = ({onClose,visible,refresh}) => {
  const [form] = Form.useForm();

  const [userData,setUserData] = useState({
    username:"",
    password:"",
    ronin:"",
    role:""
  });
  const [addUser] = useMutation(UserMutation(userData.username,userData.password,userData.ronin,userData.role),{
    onCompleted: data => {
      if(data && data.UserMutation){
        refresh()
        return message.success("Successfully Created User: "+userData.username)
      }
    }
  });
  
  const formData = (event) => setUserData({
      ...userData,
      [event.target.name] : event.target.value
  });

  const selectRole = (value) => setUserData({
      ...userData,
      "role" : value
  });

  const createUser = () => {
    form.resetFields()
    addUser()
    onClose()
    return message.info("Creating User"+userData.username+"...")
  }

  useEffect(()=>form.resetFields(),[visible])
  return(<Drawer
      title="Create a new account"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onClose} type="primary">
            Submit
          </Button>
        </Space>
      }
    >
    <Form form={form} layout="vertical" hideRequiredMark>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="username"
            onChange={formData}
            label="Username"
            rules={[{ required: true, message: 'Please enter user name' }]}
          >
            <Input name="username" placeholder="e.g. authorizer" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="password"
            onChange={formData}
            label="Password"
            rules={[{ required: true, message: 'Please enter password' }]}
          >
            <Input name="password" type="password" placeholder="e.g. passowrd" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="ronin"
            onChange={formData}
            label="Ronin"
            rules={[{ required: true, message: 'Please enter ronin address' }]}
          >
            <Input name="ronin" placeholder="e.g. 0a1b2c3d4e5f" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="role"
            
            label="Role"
            rules={[{ required: true, message: 'Please choose role' }]}
          >
            <Select onChange={selectRole} name="role" placeholder="Please choose role">
              <Option value="BREEDER">Breeder</Option>
              <Option value="AUTHORIZER">Authorizer</Option>
              <Option value="SUPER_AUTHORIZER">Super Authorizer</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
            <Button onClick={createUser} style={{float:'right',background:'linear-gradient(272.64deg, #3BE0D6 0%, #35B1FC 33.61%, #4D94FD 66.95%, #6D6DFF 100%)', border:'unset', color:'black'}}>
              CREATE USER
            </Button>
        </Col>
      </Row>
    </Form>
  </Drawer>)
}

export default AddUserDrawer
