import React,{useState,useEffect,useMemo} from "react";
import ReactDOM from "react-dom";

import {
  useQuery
} from "@apollo/client";

import {
  UsersQuery,
} from '../../graphql/queries';

import AddUserDrawer from "../../components/AddUserDrawer";
import UpdateUserDrawer from "../../components/UpdateUserDrawer";
import Header from '../../components/Header/Header'

// import AxieBreedingMenu from "../components/AxieBreedingMenu";

import { Breadcrumb, Typography, Row, Col, Table, Radio, Button} from 'antd';

const { Title } = Typography;

const Users = (props) => {

  const { data, loading, error, refetch } = useQuery(UsersQuery);
  const [user,setUsers] = useState();

  useEffect(()=>{
    let temp 
    if(data && data.UsersQuery)
    {
      temp = data.UsersQuery.map((item,key)=>{
        return{
          ...item,
          key:key
        }
      })
      setUsers(temp)
    }
  },[data]);

  const columns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   ellipsis:true,
  //   //render: (text) => <a href="#">{text}</a>
  // },
  {
    title: 'Username',
    dataIndex: 'username',
  },
  {
    title: 'Role',
    dataIndex: 'role',
  },
  {
    title: 'Ronin',
    dataIndex: 'ronin',
    ellipsis:true
  }]
  const [selectedUser,setSelectedUser] = useState({
    id:"",
    username:"",
    password:"",
    ronin:"",
    role:"",
  });

  // const [selRow, setSelRow] = useState([]);
  // const rowSelection = {
  //   type:'radio',
  //   selectedRowKeys:selRow,
  //   preserveSelectedRowKeys:false,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  //     setSelRow(selectedRowKeys);
  //     setSelectedUser(selectedRows[0]);
  //   },
  //   getCheckboxProps: (record) => ({
  //     //disabled: record.name === 'Disabled User',
  //     // Column configuration not to be checked
  //     name: "selectedRows",
  //   }),
  // };

  const [visibleAddUser,setVisibleAddUser] = useState(false);
  const [visibleUpdateUser,setVisibleUpdateUser] = useState(false);

  const onCloseAddUser = () => {
    //setSelRow(null);
    setVisibleAddUser(false)
  }

  const onCloseUpdateUser = () => {    
    //setSelRow(null);
    setVisibleUpdateUser(false)
  }

  const showDrawerAddUser = () => setVisibleAddUser(true)
  const showDrawerUpdateUser = () => setVisibleUpdateUser(true)


  const initialValue = useMemo(()=>{
    let shallow = selectedUser
    delete shallow.key
    delete shallow.__typename
    return shallow
  },[selectedUser])
  return(
    <>
    <AddUserDrawer
          visible={visibleAddUser && !loading}
          onClose={onCloseAddUser}
          refresh={refetch}
          />
    
    {visibleUpdateUser && <UpdateUserDrawer
              visible={visibleUpdateUser && !loading}
              onClose={onCloseUpdateUser}
              id={selectedUser.id}
              username={selectedUser.username}
              password={selectedUser.password}
              role={selectedUser.role}
              ronin={selectedUser.ronin}
              refresh={refetch}
              initialValue={initialValue}
              parentUpdate={setUsers}
              />}
    
    <Header title='Users' />
    <Row>
      <Col xs={24} xl={24}>
            {/*<Button onClick={showDrawerUpdateUser} style={{float:'right',background:'#333333', border:'unset', color:'black', marginLeft:'1em'}}>
              UPDATE USER
            </Button>*/}
            <Button onClick={showDrawerAddUser} style={{float:'right',background:'linear-gradient(272.64deg, #3BE0D6 0%, #35B1FC 33.61%, #4D94FD 66.95%, #6D6DFF 100%)', border:'unset', color:'black'}}>
              + CREATE USER
            </Button>
            <br/>
            <br/>
        <Table
           onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setSelectedUser(record);
                showDrawerUpdateUser();
              }, // click row
              // onDoubleClick: event => {}, // double click row
              // onContextMenu: event => {}, // right button click row
              // onMouseEnter: event => {}, // mouse enter row
              // onMouseLeave: event => {}, // mouse leave row
            };
          }}
          //rowSelection={rowSelection}
          className="table"
          columns={columns}
          pagination={false}
          dataSource={!loading && user}
          loading={loading}/>
      </Col>
    </Row>
    <br/>
    </>
  )
}

export default Users
