import {
  ApolloClient,
  InMemoryCache,
  //ApolloProvider,
  //useQuery,
  //gql
  ApolloLink,
  from,
  HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GQL,
  headers:{
    Authorization: `Bearer ${localStorage.getItem('appToken') || null}`
  }
});

const errorLink = onError(({ graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  const {result} = networkError;
  //Handle global errors here
  if(result.error === "jwt expired")
  {
    localStorage.removeItem('appToken');
    window.location.reload();
  }
});

const authLink = new ApolloLink((operation,forward)=>{
  return forward(operation).map(data=>{
    return data;
  })
})

const client = new ApolloClient({
  link: from([errorLink,authLink,httpLink]),
  //uri: 'https://graphql-gateway.axieinfinity.com/graphql',
  cache: new InMemoryCache(),
});


export default client;