import React,{useState,useEffect,useContext} from "react";
// import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

import Sidebar from "../components/Sidebar";
import { Layout } from 'antd';
import {
  AppContextProvider,
  AppContext
} from "../context/AppContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Page404 from "./Page404";
import Login from "./auth/Login";
import Dashboard from "./dashboard/Dashboard";
// import Breeding from "./breeding/Breeding";
import Users from "./users/Users";
import SearchAxie from "./r1r2-searcher/SearchAxie";
import SearchAxieV2 from "./algo-searcher/SearchAxieV2";
import BuildAlert from "./build-alert/BuildAlert";
import Profitability from "./profitability/calculator";
import CrabadaPartCalculator from "./crabada-part-calculator/CrabadaPartCalculator";
import CrabadaSearcher from "./crabada-searcher/CrabadaSearcher";
import NinnekoSearcher from "./ninneko-searcher/NinnekoSearcher";
import CyptoUnicornSearcher from "./cypto-unicorn-searcher/CyptoUnicornSearcher";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const { Content } = Layout;
 
export default function AppWrapper (props) {
  const [collapsed,setCollapsed] = useState(false);

  const state = useContext(AppContext);
  const onCollapse = event => {
    setCollapsed(!collapsed);
  };

  let routes = [
    {
      path:'/login',
      component: Login,
      protected: false,
      available: true
    },
    {
      path: '/admin/users',
      component: Users,
      protected:true,
      available: true//(state.auth.role == "admin") ? true : false,
    },
    {
      path:'/admin/dashboard',
      component: Dashboard,
      protected: true,
      available: true,
    },
    {
      path:'/admin/search',
      component: SearchAxie,
      protected: true,
      available: true
    },
    {
      path:'/admin/searchv2',
      component: SearchAxieV2,
      protected: true,
      available: true
    },
    {
      path:'/admin/build-alert',
      component: BuildAlert,
      protected: true,
      available: true
    },
    {
      path:'/admin/profitability',
      component: Profitability,
      protected: true,
      available: true
    },
    {
      path:'/admin/crabada-part-calculator',
      component: CrabadaPartCalculator,
      protected: true,
      available: true
    },
    {
      path:'/admin/crabada-searcher',
      component: CrabadaSearcher,
      protected: true,
      available: true
    },
    {
      path:'/admin/ninneko-searcher',
      component: NinnekoSearcher,
      protected: true,
      available: true
    },
    {
      path:'/admin/crypto-unicorn-searcher',
      component: CyptoUnicornSearcher,
      protected: true,
      available: true
    },
    // {
    //   path:'/app/breeding',
    //   component: Breeding,
    //   protected: true
    // }
  ]

  return (
    <AppContextProvider>
      <Layout style={{ minHeight: '100vh' }}> 
        <Router>
          {
            state.auth.accessToken && <Sidebar 
              title="App"
              collapsed={collapsed} 
              onCollapse={onCollapse}> 
            </Sidebar>

          }
          <Layout className={`site-layout ${state.auth.accessToken === null ? 'login' : ''}`}>
            <Content style={{ margin: '0 16px'}}>
              <ScrollToTop />
                <Switch>
                  {
                    <Route
                        exact
                        path="/" //Default Route
                        render={() => {
                            return (
                              state.auth.accessToken?
                              <Redirect to="/admin/users" /> :
                              <Redirect to="/login" /> 
                            )
                        }}
                      />
                  }
                  {
                    routes.filter(i=>i.available).map(item=>{
                      if(item.protected) {
                        if(state.auth.accessToken) {
                          return <Route exact path={item.path} component={item.component}/> 
                        }
                        else {
                          return <Redirect to="/login" />
                        }
                      }
                      else {
                        return <Route exact path={item.path} component={item.component}/> 
                      }
                    })
                  }
                </Switch>
            </Content>
            <ToastContainer />
            {/*<Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>*/}
          </Layout>
        </Router>
      </Layout>
    </AppContextProvider>
  );
}

