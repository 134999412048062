import React,{useContext} from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { DesktopOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom'
import { AppContext } from "../context/AppContext";
import { ReactComponent as AppLogo } from '../images/icons/app-logo.svg';

const { Sider } = Layout

const Sidebar = (props) => {
    const state = useContext(AppContext);
    const location = useLocation();
    
    return (
        <Sider width={250} className="sidebar-menu" collapsible collapsed={props.collapsed} onCollapse={props.onCollapse}>
            <div className="bd-logo">
                <AppLogo />
            </div>
            {/*<Title level={2} className="title center-text">
        {props.title}
      </Title>*/}
            <Menu className="sidebar-menu-menu" defaultSelectedKeys={[location.pathname]} mode="inline">
                <Menu.Item key="/admin/dashboard" className="--dashboard">
                    <Link to="/admin/dashboard">
                        Dashboard
                    </Link>
                </Menu.Item>

                <Menu.SubMenu title="Axie Infinity" className="axie-infinity">
                    <Menu.Item key="/admin/searchv2">
                        <Link to="/admin/searchv2">
                            Axie Searcher
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/build-alert">
                        <Link to="/admin/build-alert">
                            Axie Build Alerts
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/profitability">
                        <Link to="/admin/profitability">
                            Axie Profit Calculator
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                {/* <Menu.Item key="/admin/search">
                    <Link to="/admin/search">
                        R1R2 Searcher
                    </Link>
                </Menu.Item> */}

                <Menu.SubMenu title="Crabada" className="crabada">
                    <Menu.Item key="/admin/crabada-searcher">
                        <Link to="/admin/crabada-searcher">
                            Crabada Searcher
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/admin/crabada-part-calculator">
                        <Link to="/admin/crabada-part-calculator">
                            Crabada Part Calculator
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                
                <Menu.SubMenu title="Ninneko" className="ninneko">
                    <Menu.Item key="/admin/ninneko-searcher">
                        <Link to="/admin/ninneko-searcher">
                            Ninneko Searcher
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
                
                <Menu.SubMenu title="Crypto Unicorns" className="crypto-unicorns">
                    <Menu.Item key="/admin/crypto-unicorn-searcher">
                        <Link to="/admin/crypto-unicorn-searcher">
                            Crypto Unicorn Searcher
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
                
                { state.auth.role === "admin" && 
                    <Menu.Item key="/admin/users" icon={<DesktopOutlined />}>
                        <Link to="/admin/users">
                            Users
                        </Link>
                    </Menu.Item> 
                }
                {/*<Menu.Item key="2" icon={<DesktopOutlined />}>
            <Link to="/app/breeding">
            Breeding
            </Link>
          </Menu.Item>*/}
            </Menu>
        </Sider>
    )
}

export default Sidebar;